import { createGlobalStyle } from 'styled-components';
import { typography } from './styles';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${typography.type.primary};
    font-size: ${typography.size.paragraph.xs};

    code {
      font-family: ${typography.type.code};
    }

    * {
      box-sizing: border-box;
      margin: 0;
    }
  }
`;
