import {
  color,
  gradient,
  border,
  opacity,
  shadows,
  spacing,
  breakpoints,
  zIndex,
  grid,
  scrollbar,
} from './styles';

const reshapedColorTheme = {
  neutralHigh: {
    v100: color.neutralHigh.v100.color,
    v200: color.neutralHigh.v200.color,
    v300: color.neutralHigh.v300.color,
    v400: color.neutralHigh.v400.color,
    v500: color.neutralHigh.v500.color,
  },
  neutralLow: {
    v100: color.neutralLow.v100.color,
    v200: color.neutralLow.v200.color,
    v300: color.neutralLow.v300.color,
    v400: color.neutralLow.v400.color,
    v500: color.neutralLow.v500.color,
  },
  primary: {
    v100: color.primary.v100.color,
    v200: color.primary.v200.color,
    v300: color.primary.v300.color,
    v400: color.primary.v400.color,
    v500: color.primary.v500.color,
  },
  secondary: {
    v100: color.secondary.v100.color,
    v200: color.secondary.v200.color,
    v300: color.secondary.v300.color,
    v400: color.secondary.v400.color,
    v500: color.secondary.v500.color,
  },
  highlight: {
    v100: color.highlight.v100.color,
    v200: color.highlight.v200.color,
    v300: color.highlight.v300.color,
    v400: color.highlight.v400.color,
    v500: color.highlight.v500.color,
  },
  info: {
    v100: color.info.v100.color,
    v200: color.info.v200.color,
    v300: color.info.v300.color,
    v400: color.info.v400.color,
    v500: color.info.v500.color,
  },
  success: {
    v100: color.success.v100.color,
    v200: color.success.v200.color,
    v300: color.success.v300.color,
    v400: color.success.v400.color,
    v500: color.success.v500.color,
  },
  error: {
    v100: color.error.v100.color,
    v200: color.error.v200.color,
    v300: color.error.v300.color,
    v400: color.error.v400.color,
    v500: color.error.v500.color,
  },
  warning: {
    v100: color.warning.v100.color,
    v200: color.warning.v200.color,
    v300: color.warning.v300.color,
    v400: color.warning.v400.color,
    v500: color.warning.v500.color,
  },
  communication: {
    v100: color.communication.v100.color,
    v200: color.communication.v200.color,
    v300: color.communication.v300.color,
    v400: color.communication.v400.color,
    v500: color.communication.v500.color,
  },
  coffee: {
    v100: color.coffee.v100.color,
    v200: color.coffee.v200.color,
    v300: color.coffee.v300.color,
    v400: color.coffee.v400.color,
    v500: color.coffee.v500.color,
  },
  transparent: 'transparent',
} as const;

export const theme = {
  color: reshapedColorTheme,
  gradient,
  border,
  opacity,
  shadows,
  spacing,
  breakpoints,
  zIndex,
  grid,
  scrollbar,
};
