import { create } from '@storybook/theming';
import logo from '../public/logo_bornlogic.svg';
import { border, color, typography } from '../src/shared';

export default create({
  base: 'light', // this will inherit the base properties of Storybooks'light theme

  // Base color
  colorSecondary: color.primary.v100.color, // Primary color

  // UI
  appBg: color.neutralHigh.v200.color,
  appContentBg: color.neutralHigh.v100.color,
  appBorderColor: color.neutralHigh.v300.color,
  appBorderRadius: border.radius.xs,

  // Typography
  fontBase: typography.type.primary,
  fontCode: typography.type.code,

  // Text colors
  textColor: color.neutralLow.v100.color,
  textInverseColor: color.neutralHigh.v100.color,
  textMutedColor: color.neutralHigh.v400.color,

  // Toolbar default and active colors
  barTextColor: color.neutralHigh.v500.color,
  barSelectedColor: color.primary.v100.color,
  barBg: color.neutralHigh.v100.color,

  // Form colors
  inputBg: color.neutralHigh.v100.color,
  inputBorder: color.neutralHigh.v300.color,
  inputTextColor: color.neutralLow.v100.color,
  inputBorderRadius: border.radius.xs,

  // Brand assets
  brandTitle: 'reBorn',
  brandUrl: 'https://bornlogic.com',
  brandImage: logo,
});
