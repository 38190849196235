import { css } from 'styled-components';

export const desktopTypography = {
  type: {
    primary: '"Lato", "Helvetica Neue", Helvetica, sans-serif',
    code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  weight: {
    regular: '400',
    medium: '600',
    bold: '700',
  },
  size: {
    paragraph: {
      xxxs: '12px',
      xxs: '14px',
      xs: '16px',
      sm: '20px',
      md: '24px',
      lg: '32px',
      xl: '40px',
      xxl: '48px',
    },
    lineHeight: {
      default: 1,
      xs: 1.15,
      sm: 1.2,
      md: 1.33,
      lg: 1.5,
      xl: 1.7,
      xxl: 2,
    },
  },
} as const;

const mobileTypography = {
  ...desktopTypography,
  size: {
    ...desktopTypography.size,
    heading: {
      h1: '3.3331rem',
      h2: '2.6669rem',
      h3: '2rem',
      h4: '1.6669rem',
    },
    subtitle: {
      medium: '1.5rem',
      small: '1.5rem',
    },
  },
} as const;

export const color = {
  neutralHigh: {
    v100: {
      color: '#ffffff',
      bg: '#000000',
    },
    v200: {
      color: '#FAFAFA',
      bg: '#000000',
    },
    v300: {
      color: '#F7F7F7',
      bg: '#000000',
    },
    v400: {
      color: '#CCCCCC',
      bg: '#000000',
    },
    v500: {
      color: '#999999',
      bg: '#000000',
    },
  },
  neutralLow: {
    v100: {
      color: '#000000',
      bg: '#ffffff',
    },
    v200: {
      color: '#757575',
      bg: '#ffffff',
    },
    v300: {
      color: '#616161',
      bg: '#ffffff',
    },
    v400: {
      color: '#333333',
      bg: '#ffffff',
    },
    v500: {
      color: '#131313',
      bg: '#ffffff',
    },
  },
  primary: {
    v100: {
      color: '#5E60EB',
      bg: '#ffffff',
    },
    v200: {
      color: '#D3D4EB',
      bg: '#ffffff',
    },
    v300: {
      color: '#2328EB',
      bg: '#ffffff',
    },
    v400: {
      color: '#1A1CAB',
      bg: '#ffffff',
    },
    v500: {
      color: '#10126B',
      bg: '#ffffff',
    },
  },
  secondary: {
    v100: {
      color: '#7116E9',
      bg: '#ffffff',
    },
    v200: {
      color: '#DDD3EB',
      bg: '#000000',
    },
    v300: {
      color: '#985EEB',
      bg: '#000000',
    },
    v400: {
      color: '#591AAB',
      bg: '#ffffff',
    },
    v500: {
      color: '#38106B',
      bg: '#ffffff',
    },
  },
  highlight: {
    v100: {
      color: '#E0157A',
      bg: '#ffffff',
    },
    v200: {
      color: '#EBD3DF',
      bg: '#000000',
    },
    v300: {
      color: '#EB5EA4',
      bg: '#000000',
    },
    v400: {
      color: '#AB1B63',
      bg: '#ffffff',
    },
    v500: {
      color: '#6B113E',
      bg: '#ffffff',
    },
  },
  info: {
    v100: {
      color: '#23CCEB',
      bg: '#000000',
    },
    v200: {
      color: '#D5F5FB',
      bg: '#000000',
    },
    v300: {
      color: '#5ED5EB',
      bg: '#000000',
    },
    v400: {
      color: '#36A1CE',
      bg: '#000000',
    },
    v500: {
      color: '#205369',
      bg: '#ffffff',
    },
  },
  success: {
    v100: {
      color: '#0A7B5D',
      bg: '#FFFFFF',
    },
    v200: {
      color: '#89E5CD',
      bg: '#000000',
    },
    v300: {
      color: '#00CF98',
      bg: '#000000',
    },
    v400: {
      color: '#006147',
      bg: '#FFFFFF',
    },
    v500: {
      color: '#003F2E',
      bg: '#ffffff',
    },
  },
  error: {
    v100: {
      color: '#E80202',
      bg: '#FFFFFF',
    },
    v200: {
      color: '#FEC8C8',
      bg: '#000000',
    },
    v300: {
      color: '#FD5959',
      bg: '#000000',
    },
    v400: {
      color: '#BF1D1D',
      bg: '#FFFFFF',
    },
    v500: {
      color: '#801313',
      bg: '#ffffff',
    },
  },
  warning: {
    v100: {
      color: '#FD7929',
      bg: '#000000',
    },
    v200: {
      color: '#FEE7DC',
      bg: '#000000',
    },
    v300: {
      color: '#FD8F40',
      bg: '#000000',
    },
    v400: {
      color: '#BF5B1D',
      bg: '#000000',
    },
    v500: {
      color: '#7B3A0F',
      bg: '#FFFFFF',
    },
  },
  communication: {
    v100: {
      color: '#FEDB4D',
      bg: '#000000',
    },
    v200: {
      color: '#FFF7D6',
      bg: '#000000',
    },
    v300: {
      color: '#FFE373',
      bg: '#000000',
    },
    v400: {
      color: '#BFA439',
      bg: '#000000',
    },
    v500: {
      color: '#5E5C1C',
      bg: '#FFFFFF',
    },
  },
  coffee: {
    v100: {
      color: '#99661F',
      bg: '#FFFFFF',
    },
    v200: {
      color: '#EBC289',
      bg: '#000000',
    },
    v300: {
      color: '#C48B3B',
      bg: '#000000',
    },
    v400: {
      color: '#664414',
      bg: '#000000',
    },
    v500: {
      color: '#4D330F',
      bg: '#FFFFFF',
    },
  },
  transparent: 'transparent',
} as const;

export const gradient = {
  info: {
    color: ' linear-gradient(225deg, #2328EB 0%, #23CCEB 100%)',
    bg: '#2328EB',
  },
  highlight: {
    color: 'linear-gradient(225deg, #E0157A 0%, #FEDB4D 100%)',
    bg: '#E0157A',
  },
  secondary: {
    color: 'linear-gradient(225deg, #7116E9 0%, #E0157A 100%)',
    bg: '#7116E9',
  },
  communication: {
    color: 'linear-gradient(225deg, #23CCEB 0%, #FEDB4D 100%)',
    bg: '#23CCEB',
  },
};

export const border = {
  radius: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    pill: '500px',
    circular: '50%',
  },
  width: {
    xs: '1px',
    sm: '2px',
    mg: '4px',
    lg: '8px',
  },
} as const;

export const opacity = {
  heavy: 0.8,
  strong: 0.64,
  intense: 0.32,
  medium: 0.16,
  light: 0.08,
};

export const shadows = {
  level1: css`
    box-shadow: -1px 4px 8px 0px rgba(51, 51, 51, 0.16);
    -webkit-box-shadow: -1px 4px 8px 0px rgba(51, 51, 51, 0.16);
    -moz-box-shadow: -1px 4px 8px 0px rgba(51, 51, 51, 0.16);
  `,
  level2: css`
    box-shadow: -1px 8px 24px 0px rgba(51, 51, 51, 0.32);
    -webkit-box-shadow: -1px 8px 24px 0px rgba(51, 51, 51, 0.32);
    -moz-box-shadow: -1px 8px 24px 0px rgba(51, 51, 51, 0.32);
  `,
  level3: css`
    box-shadow: -1px 16px 32px 0px rgba(51, 51, 51, 0.32);
    -webkit-box-shadow: -1px 16px 32px 0px rgba(51, 51, 51, 0.32);
    -moz-box-shadow: -1px 16px 32px 0px rgba(51, 51, 51, 0.32);
  `,
  level4: css`
    box-shadow: -1px 16px 48px 0px rgba(51, 51, 51, 0.32);
    -webkit-box-shadow: -1px 16px 48px 0px rgba(51, 51, 51, 0.32);
    -moz-box-shadow: -1px 16px 48px 0px rgba(51, 51, 51, 0.32);
  `,
};

export const spacing = {
  nano: '4px',
  xxxs: '8px',
  xxs: '16px',
  xs: '24px',
  sm: '32px',
  md: '40px',
  lg: '48px',
  xl: '56px',
  xxl: '64px',
  xxxl: '72px',
  giant: '80px',
  huge: '160px',
  inset: {
    nano: '4px',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xg: '40px',
  },
} as const;

export const breakpoints = {
  xsmall: '600px',
  small: '1240px',
  medium: '1366px',
  large: '1440px',
} as const;

let isMobile = window.innerHeight <= parseInt(breakpoints.xsmall.replace('px', ''), 10);

document.addEventListener('resize', () => {
  isMobile = window.innerHeight <= parseInt(breakpoints.xsmall.replace('px', ''), 10);
});

export const typography = isMobile ? desktopTypography : mobileTypography;

export const zIndex = {
  notificationBadge: 10,
  drawer: 10,
  splitView: 10,
  toolTip: 12,
  alert: 11,
  dropMenu: 12,
  modal: 10,
  dropdown: 11,
  popover: 11,
  contextMenu: 13,
} as const;

export type GRID = {
  id: 'xsmall' | 'small' | 'medium' | 'large';
  breakpoint: string;
  columns: 4 | 8 | 12;
  gutter: string;
  device: string;
};

export const grid = {
  xsmall: {
    id: 'xsmall',
    breakpoint: `(max-width: ${breakpoints.xsmall})`,
    columns: 4,
    gutter: spacing.xxs,
    device: 'mobile',
  },
  small: {
    id: 'small',
    breakpoint: `(max-width: ${breakpoints.small})`,
    columns: 8,
    gutter: spacing.xs,
    device: 'tablet / notebook',
  },
  medium: {
    id: 'medium',
    breakpoint: `(max-width: ${breakpoints.medium})`,
    columns: 12,
    gutter: spacing.xs,
    device: 'desktop',
  },
  large: {
    id: 'large',
    breakpoint: `(min-width: ${breakpoints.large})`,
    columns: 12,
    gutter: spacing.xs,
    device: 'ultra-wide',
  },
} as const;

export const scrollbar = css`
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: ${border.radius.sm};
  }

  ::-webkit-scrollbar-track {
    background-color: ${color.neutralHigh.v100.color};
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${color.neutralHigh.v400.color};
    border-radius: ${border.radius.sm};
    border: ${border.width.mg} solid ${color.neutralHigh.v100.color};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${color.neutralLow.v300.color};
  }
`;
